export const ROUTE_LIST = {
  "": true,
  "music-generator": true,
  "music-assistant": true,
  about: true,
  contact: true,
};

export const TRACK_LIST = [
  { id: 1, audioUrl: "assets/audio/LEXUS 1 BADDIE LOOP BY DJPC (TRAP).wav", name: "LEXUS 1 BADDIE LOOP BY DJPC (TRAP)", image: "assets/images/track/1.jpeg", djImage: "assets/images/track/dj1.png" },
  { id: 2, audioUrl: "assets/audio/LEXUS 2 RIDE THE WAVE BY DJPC (HOUSE TRAP).wav", name: "LEXUS 2 RIDE THE WAVE BY DJPC (HOUSE TRAP)", image: "assets/images/track/2.jpeg", djImage: "assets/images/track/dj2.png" },
  { id: 3, audioUrl: "assets/audio/LEXUS 3 RELAX BY DJPC (LOFI).wav", name: "LEXUS 3 RELAX BY DJPC (LOFI)", image: "assets/images/track/3.jpeg", djImage: "assets/images/track/dj3.png" },
  { id: 4, audioUrl: "assets/audio/LEXUS 4 GOING SOMEWHERE BY DJPC (DANCE).wav", name: "LEXUS 4 GOING SOMEWHERE BY DJPC (DANCE)", image: "assets/images/track/4.jpeg", djImage: "assets/images/track/dj4.png" },
  { id: 5, audioUrl: "assets/audio/Lexus 808 Lounge 8.mp3", name: "Lexus 808 Lounge 8", image: "assets/images/track/5.jpeg", djImage: "assets/images/track/dj1.png" },
  { id: 6, audioUrl: "assets/audio/LEXUS DANCE- We Got It ! copy.mp3", name: "LEXUS DANCE- We Got It ! copy", image: "assets/images/track/6.jpeg", djImage: "assets/images/track/dj2.png" },
  { id: 7, audioUrl: "assets/audio/Lexus Electro Rock 4.mp3", name: "Lexus Electro Rock 4", image: "assets/images/track/7.jpeg", djImage: "assets/images/track/dj3.png" },
  { id: 8, audioUrl: "assets/audio/Lexus Ghetto_Grooveshack 2.mp3", name: "Lexus Ghetto_Grooveshack 2", image: "assets/images/track/8.jpeg", djImage: "assets/images/track/dj4.png" },
  { id: 9, audioUrl: "assets/audio/Lexus House 58.mp3", name: "Lexus House 58", image: "assets/images/track/9.jpeg", djImage: "assets/images/track/dj1.png" },
  { id: 10, audioUrl: "assets/audio/LEXUS HOUSE o - Sunset  copy.mp3", name: "LEXUS HOUSE o - Sunset  copy", image: "assets/images/track/10.jpeg", djImage: "assets/images/track/dj2.png" },
  { id: 11, audioUrl: "assets/audio/LEXUS TRACK- IM BACK.mp3", name: "LEXUS TRACK- IM BACK", image: "assets/images/track/11.jpeg", djImage: "assets/images/track/dj3.png" },
  { id: 12, audioUrl: "assets/audio/LEXUS TRAP-  In my Favor copy.mp3", name: "LEXUS TRAP-  In my Favor copy", image: "assets/images/track/12.jpeg", djImage: "assets/images/track/dj4.png" },
]

export const GENRE_LIST = [
  "16bit",
  "2010s pop",
  "2step",
  "8bit",
  "acid house",
  "acid jazz",
  "acoustic group",
  "Afro Trap",
  "afrobeats",
  "alternative",
  "Alternative Hip Hop",
  "Alternative Hiphop",
  "alternative rock",
  "ambient",
  "anime song",
  "aor",
  "bachata",
  "ballad",
  "baroque",
  "beats",
  "beautiful",
  "beethoven",
  "big band",
  "big beat",
  "big room house",
  "blue note",
  "bluegrass",
  "blues",
  "boom bap",
  "bossa",
  "bossa nova",
  "brass",
  "breakbeats",
  "breakcore",
  "britpop",
  "brostep",
  "bubblegum pop",
  "build up",
  "calypso",
  "caribbean",
  "chacha",
  "chamber music",
  "children",
  "chillhop",
  "chillstep",
  "chillwave",
  "chinese",
  "chiptune",
  "cinematic",
  "citypop",
  "classic house",
  "classical",
  "classical piano",
  "colour bass",
  "Colour House",
  "comedy",
  "complextro",
  "contemporary",
  "country",
  "cumbia",
  "dance",
  "dancehall",
  "darkwave",
  "death metal",
  "deathcore",
  "deep dubstep",
  "deep house",
  "dembow",
  "dirty dubstep",
  "disco",
  "djent",
  "dnb",
  "doo",
  "doo-wop",
  "down tempo",
  "dramatic",
  "dreamwave",
  "drill",
  "drum n bass",
  "drumnbass",
  "dubstep",
  "east coast hip hop",
  "eastcoast hiphop",
  "edm",
  "Eerie",
  "electro",
  "electro house",
  "electro pop",
  "electro swing",
  "electronic",
  "electronic rock",
  "electronica",
  "elevator music",
  "emotional hardcore",
  "emotive hardcore",
  "enka",
  "euro pop",
  "eurobeat",
  "experimental",
  "fanfares",
  "fidget house",
  "flamenco",
  "folk",
  "footwork",
  "funk",
  "funky",
  "funky house",
  "fusion",
  "future bass",
  "future house",
  "futurecore",
  "gabber",
  "gagaku",
  "garage",
  "gfunk",
  "glitch hop",
  "gospel",
  "gothic",
  "gregorian",
  "grime",
  "grunge",
  "gypsy jazz",
  "halloween",
  "hard edm",
  "hard rock",
  "hard techno",
  "hardbass",
  "hardcore",
  "hip hop",
  "hiphop",
  "hymn",
  "hyper pop",
  "idm",
  "indian classical songs",
  "indie pop",
  "j",
  "j-pop",
  "jazz",
  "Jazz Ballad",
  "Jazz Ballads",
  "Jazzhop",
  "jpop",
  "jungle",
  "k",
  "k-pop",
  "kawaii future bass",
  "kawaii pop",
  "kei",
  "kizomba",
  "latin",
  "latin jazz",
  "leftfield bass",
  "lullaby",
  "Main Title",
  "marching band",
  "mariachi",
  "mathcore",
  "mathrock",
  "mazurka",
  "melbourne bounce",
  "melodic hardcore",
  "merengue",
  "metal",
  "middle east",
  "min'yo",
  "minimal",
  "modern classical",
  "modern jazz",
  "moombahton",
  "motown",
  "mozart",
  "national anthem",
  "neo soul",
  "neurofunk",
  "new age",
  "non rhythmic drones",
  "nortena",
  "nu disco",
  "Old Jazz",
  "oldie",
  "Ominous",
  "orchestral",
  "outrun",
  "phonk",
  "polka",
  "pop",
  "popwave",
  "post rock",
  "postmetal",
  "praise&worship",
  "progressive house",
  "psy trance",
  "psybient",
  "psytrance",
  "Pulses",
  "punk",
  "ragtime",
  "rap",
  "reel",
  "reggae",
  "reggaeton",
  "Retro",
  "retrowave",
  "Riff",
  "rnb",
  "rock",
  "rockabilly",
  "rocksteady",
  "rumba",
  "salsa",
  "samba",
  "scottish",
  "shibuya-kei",
  "shoegazer",
  "ska",
  "skiffle",
  "SlapHouse",
  "small emotions",
  "Smooth Jazz",
  "soca",
  "soft house",
  "songkran songs",
  "soul",
  "soundtrack",
  "spanish",
  "Strange",
  "Suspenseful",
  "swing",
  "symphonic",
  "synthpop",
  "synthwave",
  "tango",
  "tech house",
  "techcore",
  "techno",
  "teen pop",
  "thrash metal",
  "Through The Wall",
  "traditional dance",
  "tragedy",
  "trance",
  "trap",
  "tribal house",
  "trip hop",
  "tropical house",
  "vaporwave",
  "victorian",
  "visual",
  "waltz",
  "west coast hip hop",
  "westcoast hiphop",
  "wop",
  "world",
]

export const INSTRUMENT_LIST = [
  "808",
  "accordion",
  "acoustic",
  "acoustic bass",
  "acoustic drums",
  "acoustic guitar",
  "acoustic hats",
  "acoustic kicks",
  "acoustic piano",
  "acoustic snares",
  "ambiences",
  "animal",
  "arp",
  "arpeggio",
  "atmosphere",
  "bagpipe",
  "Balalika",
  "banjo",
  "bass",
  "bassoon",
  "bells",
  "bodhran",
  "bongos",
  "brass",
  "brasssection",
  "bright",
  "brushes",
  "bugle",
  "cajón",
  "carillon",
  "celesta",
  "cello",
  "chimes",
  "choir",
  "choral",
  "cinematic orchestral percussion",
  "claps",
  "clarinet",
  "clavinet",
  "clean",
  "computer",
  "conga",
  "congas",
  "cowbells",
  "crash cymbals",
  "cymbals",
  "dark",
  "didgeridoo",
  "distorted",
  "djembe",
  "drones",
  "drum kit",
  "drums",
  "electric",
  "electric bass",
  "electric guitar",
  "electric guitar - clean",
  "electric guitar - distorted",
  "electric piano",
  "electronic",
  "electronic drums",
  "electronic hats",
  "electronic kicks",
  "electronic snares",
  "erhu",
  "ethnic",
  "filtered",
  "flugelhorn",
  "flute",
  "fx",
  "game items",
  "gamelan",
  "glockenspiel",
  "gritty",
  "growl",
  "guiro",
  "guitar",
  "guzheng",
  "hand drums",
  "hard",
  "harmonica",
  "harp",
  "harpsichord",
  "hats",
  "horn",
  "horns",
  "human",
  "human beat box",
  "impact",
  "impacts",
  "industry",
  "instrumental",
  "intense",
  "kalimba",
  "kicks",
  "koto",
  "latin percussion",
  "layered",
  "lazers",
  "low",
  "lyre",
  "mallet",
  "mallets",
  "mandolin",
  "marimba",
  "mellotron",
  "melodic",
  "melodica",
  "metallic",
  "moog",
  "music box",
  "nadaswaram",
  "nature",
  "noise",
  "oboe",
  "ocarina",
  "ocean drum",
  "orchestral",
  "orchestral strings",
  "organ",
  "oud",
  "pan flute",
  "pandeiro",
  "panpipe",
  "percussion",
  "percussion kit",
  "piano",
  "piccolo",
  "Pizzicato Strings",
  "plucks",
  "processed",
  "punchy",
  "recorder",
  "reese bass",
  "reverse",
  "rhodes",
  "rhythm 2mix",
  "ride cymbals",
  "risers",
  "robotic",
  "saxophone",
  "shaker",
  "shakers",
  "shakuhachi",
  "shamisen",
  "sidechained",
  "siren",
  "sitar",
  "slapbass",
  "sleigh bells",
  "snaps",
  "snares",
  "soft",
  "stab",
  "steel drum",
  "steelpan",
  "stomps",
  "strings",
  "strings section",
  "sweeps",
  "synth",
  "synth bass",
  "synthesizer",
  "synthesizer - arpeggio",
  "synthesizer - layered",
  "synthesizer - lead",
  "synthesizer - pad",
  "synthesizer - pluck",
  "synthesizer - saw",
  "synthesizer - sidechained",
  "synthesizer - voice",
  "tabla",
  "tabra",
  "taiko",
  "tambourine",
  "texture",
  "timbales",
  "timpani",
  "toms",
  "tops",
  "transitions",
  "transportation",
  "triangle",
  "trombone",
  "trumpet",
  "tuba",
  "ukulele",
  "vibraphone",
  "vinyl",
  "viola",
  "violin",
  "vocal",
  "vocaloid",
  "water",
  "weapon",
  "wet",
  "whistle",
  "whoosh",
  "wind instruments",
  "wobble bass",
  "woodblock",
  "wooden",
  "woodwind section",
  "wurlitzer",
  "xylophone",
]

export const API_KEY = process.env.REACT_APP_API_KEY;
export const BASE_URL = process.env.REACT_APP_BASE_URLe
export const USER = "user";
export const MUSIC = "music";

// HTTPS requests
export const GET = "get";
export const POST = "post";
export const PUT = "put";
export const PATCH = "patch";
export const DELETE = "delete";
export const OPTION = "options";
