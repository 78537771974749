import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

const AudioPlayer = ({ track, playingAudioId, handlePlayigAudioId, showOptions, showDjImage }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const location = useLocation();
  const currentPath = location.pathname.split("/")[1];
  const audioRef = useRef(null);

  const handlePlay = () => {
    if (audioRef.current) {
      audioRef.current.play();
      setIsPlaying(true);
      handlePlayigAudioId(track.id);
    }
  };

  const handlePause = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      setIsPlaying(false);
      handlePlayigAudioId("");
    }
  };

  useEffect(() => {
    if (playingAudioId !== track.id) {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  }, [playingAudioId]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.addEventListener("timeupdate", () => {
        setCurrentTime(+audioRef?.current?.currentTime);
      });
      audioRef.current.addEventListener("loadedmetadata", () => {
        setDuration(audioRef?.current?.duration);
      });
    }
    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener("timeupdate", () => {
          setCurrentTime(audioRef.current.currentTime);
        });
        audioRef.current.removeEventListener("loadedmetadata", () => {
          setDuration(audioRef.current.duration);
        });
      }
    };
  }, [audioRef]);

  const showAudioActionButtons = (
    <div className="jp-volume-controls">
      <button className="jp-mute" tabIndex="0">
        <button
          type="button"
          className="btn-null mr-15"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          data-bs-title="Tooltip on top"
        >
          <i className="fas fa-link"></i>
        </button>
        <button
          type="button"
          className="btn-null mr-15"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          data-bs-title="Tooltip on top"
        >
          <i className="fas fa-heart"></i>
        </button>
        <a href={track.audioUrl} download={`${track.id}.mp3`} target="_blank">
          <button
            type="button"
            className="btn-null mr-15"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-title="Tooltip on top"
          >
            <i className="fas fa-download"></i>
          </button>
        </a>
      </button>
    </div>
  );

  const handleAudio = (e) => {
    audioRef.current.currentTime = e.target.value;
    setCurrentTime(audioRef.current.currentTime);
  };
  return (
    <div className="ganerated-music-list pick-your-tracks">
      <div className="form-check">
        {!showOptions && (
          <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
        )}
        <label className="form-check-label" htmlFor="flexRadioDefault1">
          <div className="ms-player-content">
            <div className="ms-player jp-state-playing" id="jp_container_2">
              <div className="ms-thumb">
                <img
                  src={
                    showDjImage
                      ? track?.djImage
                      : !showOptions
                      ? track?.image
                      : "assets/images/resources/music-list.jpg"
                  }
                  alt=""
                />
              </div>

              <div className="title-bar">
                <div className="ms-top">
                  <h4 className="jp-title">{track?.name}</h4>
                  <div className="jp-time-holder">
                    <div className="jp-current-time" role="timer" aria-label="time">
                      {formatTime(currentTime) || "0:00"} &nbsp;
                    </div>
                    <div className="jp-duration" role="timer" aria-label="duration">
                      {formatTime(duration) || "0:00"} &nbsp;
                    </div>
                  </div>
                </div>
                <div className="new-play-added">
                  {!isPlaying ? (
                    <div className="jp-controls text-center">
                      <button onClick={handlePlay} className="jp-play" tabIndex="0">
                        <i class="ti ti-player-play-filled"></i>
                      </button>
                    </div>
                  ) : (
                    <div className="jp-controls text-center">
                      <button onClick={handlePause} className="jp-play" tabIndex="0">
                        <i class="ti ti-player-pause-filled"></i>
                      </button>
                    </div>
                  )}
                  {/* <div className="jp-progress">
                    <div className="jp-seek-bar">
                      <div className="jp-play-bar"></div>
                    </div>
                  </div> */}
                  <input
                    onChange={handleAudio}
                    className="w-100 audio-range"
                    style={{ accentColor: "#F55050" }}
                    type="range"
                    value={currentTime}
                    min={0}
                    max={duration}
                  />
                </div>
              </div>
              <audio ref={audioRef} controls preload className="d-none">
                <source src={track?.audioUrl} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
              {currentPath === "music-generator" &&
                showAudioActionButtons
                // <div className="jp-volume-controls">
                //   <button className="jp-mute" tabIndex="0">
                //     <button
                //       type="button"
                //       className="btn-null mr-15"
                //       data-bs-toggle="tooltip"
                //       data-bs-placement="top"
                //       data-bs-title="Tooltip on top"
                //     >
                //       <i className="fas fa-link"></i>
                //     </button>
                //     <button
                //       type="button"
                //       className="btn-null mr-15"
                //       data-bs-toggle="tooltip"
                //       data-bs-placement="top"
                //       data-bs-title="Tooltip on top"
                //     >
                //       <i className="fas fa-heart"></i>
                //     </button>
                //     <a href={track.audioUrl} download={`${track.id}.mp3`} target="_blank">
                //       <button
                //         type="button"
                //         className="btn-null mr-15"
                //         data-bs-toggle="tooltip"
                //         data-bs-placement="top"
                //         data-bs-title="Tooltip on top"
                //       >
                //         <i className="fas fa-download"></i>
                //       </button>
                //     </a>
                //   </button>
                // </div>
              }
            </div>
            <div className="ganerated-music-assistant-footer">
              {showOptions && currentPath !== "music-generator" && (
                <>
                  <h5>Download & save your Track!</h5>
                  {showAudioActionButtons}
                  {/* <div className="jp-volume-controls">
                    <button className="jp-mute" tabIndex="0">
                      <button
                        type="button"
                        className="btn-null mr-15"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        data-bs-title="Tooltip on top"
                      >
                        <i className="fas fa-link"></i>
                      </button>
                      <button
                        type="button"
                        className="btn-null mr-15"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        data-bs-title="Tooltip on top"
                      >
                        <i className="fas fa-heart"></i>
                      </button>
                      <a href={track.audioUrl} download={`${track.id}.mp3`} target="_blank">
                        <button
                          type="button"
                          className="btn-null mr-15"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-title="Tooltip on top"
                        >
                          <i className="fas fa-download"></i>
                        </button>
                      </a>
                    </button>
                  </div> */}
                </>
              )}
            </div>
          </div>
        </label>
      </div>
    </div>
  );
};

export default AudioPlayer;
