import React from "react";
import { Link } from "react-router-dom";
import logoWhite from "../../assets/images/logo-main.png"

function Footer() {
  return (
    <footer className="block black-bg footer-signup-space">
            <div className="container-fluid">
                <div className="row section-title-between">
                    <div className="col-lg-8 col-md-7 col-12">
                        <div className="signup-create title-sec wow fadeInUp mb-0 text-left">
                            <h3>Sign up to create immersive audio at the intersection of music and generative AI using the Rolling Out AI Music Lab.</h3>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-5 col-12">
                        <Link to="/signup" className="btn-red  wow slideInUp float-right" >SIGN-UP TO CREATE</Link>
                    </div>
                </div>
                 <div className="row section-title-between mb-0">
                    <div className="col-lg-12 col-md-12 col-12">
                        <div className="footer-content">
                            <div className="footer-logo">
                                <img src={logoWhite} className="img-fluid logo-black" alt="logo" />
                                <p>© 2035 by Rolling Out Music Lab</p>
                            </div>
                            
                            <ul className="social-links">
                                <li><Link to="/faq" title="">FAQs</Link></li>
                                <li><Link to="/privacy" title="">Privacy</Link></li>
                                <li><Link to="/terms" title="">Terms</Link></li>
                                <li> <button className="btn-null toolpit-icon"  data-toggle="tooltip" title="SAY HELLO" ><i className="ti ti-mail"></i></button>
                                </li>
                                <li> <button className="btn-null toolpit-icon"  data-toggle="tooltip" title="FOLLOW @ ROLLINGOUT" ><i className="fab fa-instagram"></i></button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
  );
}

export default Footer;
