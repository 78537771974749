import { useEffect, useMemo, useRef, useState } from "react";
import { TRACK_LIST } from "../../app.config";
import AudioList from "../AudioList";
import InstrumentSound from "./instrumentSound";
import Tracks from "./tracks";
import AudioPlayer from "../AudioPlayer";
import GenreList from "./genreList";

const MusicAssistant = () => {
  const [fields, setFields] = useState({
    instruments: ["Bell", "Drums", "Voice"],
    genre: "Hip-Hop",
  });
  const [playingAudioId, setPlayingAudioId] = useState("");
  const [showNext, setShowNext] = useState("");
  const trackRef = useRef(null);
  const instrumentRef = useRef(null)
  const finalTrackRef = useRef(null)
  console.log('showNext: ', showNext);

  const showAudioList = useMemo(() => {
    return TRACK_LIST?.map((item, i) => {
      if(i === 0)
      return (
        <AudioPlayer
          key={item.id}
          track={item}
          playingAudioId={playingAudioId}
          handlePlayigAudioId={(id) => setPlayingAudioId(id)}
          showOptions={true}
        />
      );
    });
  }, [TRACK_LIST, playingAudioId]);

  useEffect(() => {
    if (showNext === 1 && trackRef.current) 
      trackRef.current.scrollIntoView({ behavior: "smooth" });
      if (showNext === 2 && instrumentRef.current) instrumentRef.current.scrollIntoView({ behavior: "smooth" });
      if (showNext === 3 && finalTrackRef.current) finalTrackRef.current.scrollIntoView({ behavior: "smooth" });
  },[showNext])

  return (
    <>
      <section className="block mt-5">
        <div className="container-fluid">
          <div className="row about-sec">
            <div className="col-xl-6 col-lg-12 col-md-12 col-12">
              <div className="about-us wow fadeInLeft" data-wow-delay="100ms" data-wow-duration="1000ms">
                <h2>Ready to work with our AI Music Assistant?</h2>
                <p>
                  Are you ready to collaborate with Rolling Out’s legendary AI Music Lab? Simply select the inputs and
                  customize your tracks. Then, repeat the process until you create the perfect track.Everything you
                  create is unique to you as our algorithms have been taught note-by-note alongside some of the
                  industry’s most talented producers and DJs. Our platform matches your track to the best beats and
                  royalty-free tracks. Then, it creates studio-quality music that’s tailored to your request.
                </p>
                <p>
                  Never get stuck creatively again. Generate unique tracks and customize them at the click of a button.
                  The Rolling Out Music Lab is your copilot to help you add effects and stems or change the tempo. Then,
                  our AI detects all of the different instruments and mixes everything perfectly. Then, you can download
                  your track and add it to your DAW or play it on your new Lexus TX.
                </p>

                {/* <!-- <a className="btn-outline-white  wow slideInUp" href="#" title="">Explore Now</a> --> */}
              </div>
            </div>
            <div className="col-xl-6 col-lg-12 col-md-12 col-12">
              <div className="about-image wow fadeInRight" data-wow-delay="100ms" data-wow-duration="2000ms">
                <img src="assets/images/resources/assist1.jpg" alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <GenreList handelShowNext={() => setShowNext(1)} />
      {(showNext || showNext === 1) && <Tracks trackRef={trackRef} handelShowNext={() => setShowNext(2)} />}
      {(showNext === 2 || showNext === 3) &&  <section ref={instrumentRef}
        className="block black-bg section-ai-music-genrator section-ai-music-genrator-assistant"
        id="section-ai-music-assistant"
      >
        <div className="container-fluid">
          <InstrumentSound handelShowNext={() => setShowNext(3)} />
          {/* <div className="row justify-content-center pt-3">
            <div className="col-lg-8 col-md-10 col-12">
              <div className="title-sec mb-3 text-center wow fadeInUp">
                <h2>Your Personal AI Music Assistant</h2>
                <p>
                  The Rolling Out AI Music Lab will quickly create a unique track for you. We have millions of samples;
                  all you need to do is tell us the key, instruments, genre, beat, mood or vibe you want to achieve with
                  your music. Our AI will get to work, and in a second, you’ll have an AI-generated beat or full track.
                </p>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-xl-3 col-lg-4 col-md-12 col-12">
              <div className="ai-music-genrator-main wow fadeInLeft">
                <form className="ai-music-genrator-form instruments-list">
                  <div className="basic-iput-data">
                    <div className="accordion" id="accordionPanelsStayOpenExample">
                      <div className="accordion-item mt-3">
                        <h2 className="accordion-header select-instruments-title" id="panelsStayOpen-headingTwo">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#panelsStayOpen-collapseTwo"
                            aria-expanded="true"
                            aria-controls="panelsStayOpen-collapseTwo"
                          >
                            Instruments
                          </button>
                        </h2>
                        <div
                          id="panelsStayOpen-collapseTwo"
                          className="accordion-collapse collapse show"
                          aria-labelledby="panelsStayOpen-headingTwo"
                        >
                          <div className="accordion-body">
                            <div className="ai-music-genrator-form instruments-list select-instruments">
                              <div className="form-check">
                                <div className="d-flex align-items-center">
                                  <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault1" />
                                  <label className="form-check-label" htmlFor="flexCheckDefault1">
                                    Piano
                                  </label>
                                </div>
                                <div className="qty">
                                  <span className="minus bg-dark">-</span>
                                  <input type="number" className="count" name="qty" value="0" />
                                  <span className="plus bg-dark">+</span>
                                </div>
                              </div>
                              <div className="form-check">
                                <div className="d-flex align-items-center">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckChecked2"
                                    checked
                                  />
                                  <label className="form-check-label" htmlFor="flexCheckChecked2">
                                    Keyboard
                                  </label>
                                </div>
                                <div className="qty">
                                  <span className="minus bg-dark">-</span>
                                  <input type="number" className="count" name="qty" value="0" />
                                  <span className="plus bg-dark">+</span>
                                </div>
                              </div>
                              <div className="form-check">
                                <div className="d-flex align-items-center">
                                  <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault3" />
                                  <label className="form-check-label" htmlFor="flexCheckDefault3">
                                    Acoustic Guitar
                                  </label>
                                </div>
                                <div className="qty">
                                  <span className="minus bg-dark">-</span>
                                  <input type="number" className="count" name="qty" value="0" />
                                  <span className="plus bg-dark">+</span>
                                </div>
                              </div>
                              <div className="form-check">
                                <div className="d-flex align-items-center">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckChecked4"
                                    checked
                                  />
                                  <label className="form-check-label" htmlFor="flexCheckChecked4">
                                    Electric Guitar
                                  </label>
                                </div>
                                <div className="qty">
                                  <span className="minus bg-dark">-</span>
                                  <input type="number" className="count" name="qty" value="0" />
                                  <span className="plus bg-dark">+</span>
                                </div>
                              </div>
                              <div className="form-check">
                                <div className="d-flex align-items-center">
                                  <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault5" />
                                  <label className="form-check-label" htmlFor="flexCheckDefault5">
                                    Bell
                                  </label>
                                </div>
                                <div className="qty">
                                  <span className="minus bg-dark">-</span>
                                  <input type="number" className="count" name="qty" value="0" />
                                  <span className="plus bg-dark">+</span>
                                </div>
                              </div>
                              <div className="form-check">
                                <div className="d-flex align-items-center">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckChecked6"
                                    checked
                                  />
                                  <label className="form-check-label" htmlFor="flexCheckChecked6">
                                    Mallet
                                  </label>
                                </div>
                                <div className="qty">
                                  <span className="minus bg-dark">-</span>
                                  <input type="number" className="count" name="qty" value="0" />
                                  <span className="plus bg-dark">+</span>
                                </div>
                              </div>
                              <div className="form-check">
                                <div className="d-flex align-items-center">
                                  <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault7" />
                                  <label className="form-check-label" htmlFor="flexCheckDefault7">
                                    Drums
                                  </label>
                                </div>
                                <div className="qty">
                                  <span className="minus bg-dark">-</span>
                                  <input type="number" className="count" name="qty" value="0" />
                                  <span className="plus bg-dark">+</span>
                                </div>
                              </div>
                              <div className="form-check">
                                <div className="d-flex align-items-center">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckChecked8"
                                    checked
                                  />
                                  <label className="form-check-label" htmlFor="flexCheckChecked8">
                                    Strings
                                  </label>
                                </div>
                                <div className="qty">
                                  <span className="minus bg-dark">-</span>
                                  <input type="number" className="count" name="qty" value="0" />
                                  <span className="plus bg-dark">+</span>
                                </div>
                              </div>
                              <div className="form-check">
                                <div className="d-flex align-items-center">
                                  <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault9" />
                                  <label className="form-check-label" htmlFor="flexCheckDefault9">
                                    Voice
                                  </label>
                                </div>
                                <div className="qty">
                                  <span className="minus bg-dark">-</span>
                                  <input type="number" className="count" name="qty" value="0" />
                                  <span className="plus bg-dark">+</span>
                                </div>
                              </div>
                              <div className="form-check">
                                <div className="d-flex align-items-center">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckChecked10"
                                    checked
                                  />
                                  <label className="form-check-label" htmlFor="flexCheckChecked10">
                                    Brass
                                  </label>
                                </div>
                                <div className="qty">
                                  <span className="minus bg-dark">-</span>
                                  <input type="number" className="count" name="qty" value="0" />
                                  <span className="plus bg-dark">+</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <a
                      className="btn-red mt-4 w-100  wow slideInUp"
                      id="btn-ganerate-music-assistant"
                      href="#select-instruments-song-sound"
                      title=""
                    >
                      Generate
                    </a>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-xl-9 col-lg-8 col-md-12 col-12">
              <div className="row mt-4">
                <div className="col-md-12 col-12">
                  <div className="ganerated-music-null">
                    <img src="assets/images/resources/music-headphone.png" className="img-fluid" />
                  </div>

                  <div
                    className="ganerated-music-body ganerator-song-assistant-sub mt-3"
                    id="select-instruments-song-sound"
                  >
                    <div className="playlist-info mb-3">
                      <h3>Select Your instrument sounds</h3>
                    </div>
                    <div className="pick-your-tracks-body">
                      <div className="ganerated-music-list pick-your-tracks">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                          />
                          <label className="form-check-label" htmlFor="flexRadioDefault1">
                            <div className="ms-player-content">
                              <div className="ms-player jp-state-playing" id="jp_container_2">
                                <div className="ms-thumb">
                                  <img src="assets/images/resources/music-list.jpg" alt="" />
                                </div>

                                <div className="title-bar">
                                  <div className="ms-top">
                                    <h4 className="jp-title"></h4>
                                    <div className="jp-time-holder">
                                      <div className="jp-current-time" role="timer" aria-label="time">
                                        &nbsp;
                                      </div>
                                      <div className="jp-duration" role="timer" aria-label="duration">
                                        &nbsp;
                                      </div>
                                    </div>
                                  </div>
                                  <div className="new-play-added">
                                    <div className="jp-controls text-center">
                                      <button className="jp-play" tabIndex="0">
                                        <i className="fas fa-play"></i>
                                      </button>
                                    </div>
                                    <div className="jp-progress">
                                      <div className="jp-seek-bar">
                                        <div className="jp-play-bar"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="jp-volume-controls"></div>
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>
                      <div className="ganerated-music-list pick-your-tracks">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault2"
                          />
                          <label className="form-check-label" htmlFor="flexRadioDefault2">
                            <div className="ms-player-content">
                              <div className="ms-player jp-state-playing" id="jp_container_2">
                                <div className="ms-thumb">
                                  <img src="assets/images/resources/music-list.jpg" alt="" />
                                </div>

                                <div className="title-bar">
                                  <div className="ms-top">
                                    <h4 className="jp-title"></h4>
                                    <div className="jp-time-holder">
                                      <div className="jp-current-time" role="timer" aria-label="time">
                                        &nbsp;
                                      </div>
                                      <div className="jp-duration" role="timer" aria-label="duration">
                                        &nbsp;
                                      </div>
                                    </div>
                                  </div>
                                  <div className="new-play-added">
                                    <div className="jp-controls text-center">
                                      <button className="jp-play" tabIndex="0">
                                        <i className="fas fa-play"></i>
                                      </button>
                                    </div>
                                    <div className="jp-progress">
                                      <div className="jp-seek-bar">
                                        <div className="jp-play-bar"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="jp-volume-controls"></div>
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>
                      <div className="ganerated-music-list pick-your-tracks">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault3"
                          />
                          <label className="form-check-label" htmlFor="flexRadioDefault3">
                            <div className="ms-player-content">
                              <div className="ms-player jp-state-playing" id="jp_container_2">
                                <div className="ms-thumb">
                                  <img src="assets/images/resources/music-list.jpg" alt="" />
                                </div>

                                <div className="title-bar">
                                  <div className="ms-top">
                                    <h4 className="jp-title"></h4>
                                    <div className="jp-time-holder">
                                      <div className="jp-current-time" role="timer" aria-label="time">
                                        &nbsp;
                                      </div>
                                      <div className="jp-duration" role="timer" aria-label="duration">
                                        &nbsp;
                                      </div>
                                    </div>
                                  </div>
                                  <div className="new-play-added">
                                    <div className="jp-controls text-center">
                                      <button className="jp-play" tabIndex="0">
                                        <i className="fas fa-play"></i>
                                      </button>
                                    </div>
                                    <div className="jp-progress">
                                      <div className="jp-seek-bar">
                                        <div className="jp-play-bar"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="jp-volume-controls"></div>
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>
                      <div className="ganerated-music-list pick-your-tracks">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault4"
                          />
                          <label className="form-check-label" htmlFor="flexRadioDefault4">
                            <div className="ms-player-content">
                              <div className="ms-player jp-state-playing" id="jp_container_2">
                                <div className="ms-thumb">
                                  <img src="assets/images/resources/music-list.jpg" alt="" />
                                </div>

                                <div className="title-bar">
                                  <div className="ms-top">
                                    <h4 className="jp-title"></h4>
                                    <div className="jp-time-holder">
                                      <div className="jp-current-time" role="timer" aria-label="time">
                                        &nbsp;
                                      </div>
                                      <div className="jp-duration" role="timer" aria-label="duration">
                                        &nbsp;
                                      </div>
                                    </div>
                                  </div>
                                  <div className="new-play-added">
                                    <div className="jp-controls text-center">
                                      <button className="jp-play" tabIndex="0">
                                        <i className="fas fa-play"></i>
                                      </button>
                                    </div>
                                    <div className="jp-progress">
                                      <div className="jp-seek-bar">
                                        <div className="jp-play-bar"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="jp-volume-controls"></div>
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>
                      <div className="ganerated-music-list pick-your-tracks">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault5"
                          />
                          <label className="form-check-label" htmlFor="flexRadioDefault5">
                            <div className="ms-player-content">
                              <div className="ms-player jp-state-playing" id="jp_container_2">
                                <div className="ms-thumb">
                                  <img src="assets/images/resources/music-list.jpg" alt="" />
                                </div>

                                <div className="title-bar">
                                  <div className="ms-top">
                                    <h4 className="jp-title"></h4>
                                    <div className="jp-time-holder">
                                      <div className="jp-current-time" role="timer" aria-label="time">
                                        &nbsp;
                                      </div>
                                      <div className="jp-duration" role="timer" aria-label="duration">
                                        &nbsp;
                                      </div>
                                    </div>
                                  </div>
                                  <div className="new-play-added">
                                    <div className="jp-controls text-center">
                                      <button className="jp-play" tabIndex="0">
                                        <i className="fas fa-play"></i>
                                      </button>
                                    </div>
                                    <div className="jp-progress">
                                      <div className="jp-seek-bar">
                                        <div className="jp-play-bar"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="jp-volume-controls"></div>
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>
                      <div className="ganerated-music-list pick-your-tracks">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault6"
                          />
                          <label className="form-check-label" htmlFor="flexRadioDefault6">
                            <div className="ms-player-content">
                              <div className="ms-player jp-state-playing" id="jp_container_2">
                                <div className="ms-thumb">
                                  <img src="assets/images/resources/music-list.jpg" alt="" />
                                </div>

                                <div className="title-bar">
                                  <div className="ms-top">
                                    <h4 className="jp-title"></h4>
                                    <div className="jp-time-holder">
                                      <div className="jp-current-time" role="timer" aria-label="time">
                                        &nbsp;
                                      </div>
                                      <div className="jp-duration" role="timer" aria-label="duration">
                                        &nbsp;
                                      </div>
                                    </div>
                                  </div>
                                  <div className="new-play-added">
                                    <div className="jp-controls text-center">
                                      <button className="jp-play" tabIndex="0">
                                        <i className="fas fa-play"></i>
                                      </button>
                                    </div>
                                    <div className="jp-progress">
                                      <div className="jp-seek-bar">
                                        <div className="jp-play-bar"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="jp-volume-controls"></div>
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>
                      <div className="ganerated-music-list pick-your-tracks">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault7"
                          />
                          <label className="form-check-label" htmlFor="flexRadioDefault7">
                            <div className="ms-player-content">
                              <div className="ms-player jp-state-playing" id="jp_container_2">
                                <div className="ms-thumb">
                                  <img src="assets/images/resources/music-list.jpg" alt="" />
                                </div>

                                <div className="title-bar">
                                  <div className="ms-top">
                                    <h4 className="jp-title"></h4>
                                    <div className="jp-time-holder">
                                      <div className="jp-current-time" role="timer" aria-label="time">
                                        &nbsp;
                                      </div>
                                      <div className="jp-duration" role="timer" aria-label="duration">
                                        &nbsp;
                                      </div>
                                    </div>
                                  </div>
                                  <div className="new-play-added">
                                    <div className="jp-controls text-center">
                                      <button className="jp-play" tabIndex="0">
                                        <i className="fas fa-play"></i>
                                      </button>
                                    </div>
                                    <div className="jp-progress">
                                      <div className="jp-seek-bar">
                                        <div className="jp-play-bar"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="jp-volume-controls"></div>
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>
                      <div className="ganerated-music-list pick-your-tracks">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault8"
                          />
                          <label className="form-check-label" htmlFor="flexRadioDefault8">
                            <div className="ms-player-content">
                              <div className="ms-player jp-state-playing" id="jp_container_2">
                                <div className="ms-thumb">
                                  <img src="assets/images/resources/music-list.jpg" alt="" />
                                </div>

                                <div className="title-bar">
                                  <div className="ms-top">
                                    <h4 className="jp-title"></h4>
                                    <div className="jp-time-holder">
                                      <div className="jp-current-time" role="timer" aria-label="time">
                                        &nbsp;
                                      </div>
                                      <div className="jp-duration" role="timer" aria-label="duration">
                                        &nbsp;
                                      </div>
                                    </div>
                                  </div>
                                  <div className="new-play-added">
                                    <div className="jp-controls text-center">
                                      <button className="jp-play" tabIndex="0">
                                        <i className="fas fa-play"></i>
                                      </button>
                                    </div>
                                    <div className="jp-progress">
                                      <div className="jp-seek-bar">
                                        <div className="jp-play-bar"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="jp-volume-controls"></div>
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>
                      <div className="ganerated-music-list pick-your-tracks">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault9"
                          />
                          <label className="form-check-label" htmlFor="flexRadioDefault9">
                            <div className="ms-player-content">
                              <div className="ms-player jp-state-playing" id="jp_container_2">
                                <div className="ms-thumb">
                                  <img src="assets/images/resources/music-list.jpg" alt="" />
                                </div>

                                <div className="title-bar">
                                  <div className="ms-top">
                                    <h4 className="jp-title"></h4>
                                    <div className="jp-time-holder">
                                      <div className="jp-current-time" role="timer" aria-label="time">
                                        &nbsp;
                                      </div>
                                      <div className="jp-duration" role="timer" aria-label="duration">
                                        &nbsp;
                                      </div>
                                    </div>
                                  </div>
                                  <div className="new-play-added">
                                    <div className="jp-controls text-center">
                                      <button className="jp-play" tabIndex="0">
                                        <i className="fas fa-play"></i>
                                      </button>
                                    </div>
                                    <div className="jp-progress">
                                      <div className="jp-seek-bar">
                                        <div className="jp-play-bar"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="jp-volume-controls"></div>
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>
                      <div className="ganerated-music-list pick-your-tracks">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault10"
                          />
                          <label className="form-check-label" htmlFor="flexRadioDefault10">
                            <div className="ms-player-content">
                              <div className="ms-player jp-state-playing" id="jp_container_2">
                                <div className="ms-thumb">
                                  <img src="assets/images/resources/music-list.jpg" alt="" />
                                </div>

                                <div className="title-bar">
                                  <div className="ms-top">
                                    <h4 className="jp-title"></h4>
                                    <div className="jp-time-holder">
                                      <div className="jp-current-time" role="timer" aria-label="time">
                                        &nbsp;
                                      </div>
                                      <div className="jp-duration" role="timer" aria-label="duration">
                                        &nbsp;
                                      </div>
                                    </div>
                                  </div>
                                  <div className="new-play-added">
                                    <div className="jp-controls text-center">
                                      <button className="jp-play" tabIndex="0">
                                        <i className="fas fa-play"></i>
                                      </button>
                                    </div>
                                    <div className="jp-progress">
                                      <div className="jp-seek-bar">
                                        <div className="jp-play-bar"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="jp-volume-controls"></div>
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="text-center">
                      <a
                        className="btn-red mt-4 wow slideInUp"
                        id="btn-ganerate-music"
                        href="#final-result-ai-assistant"
                        title=""
                      >
                        Generate
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {showNext === 3 && (
            <div className="row mt-4">
              <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                <div ref={finalTrackRef} className="ganerator-song-main final-ai-assistant-result" id="final-result-ai-assistant">
                  <AudioList fields={fields} showAudioList={showAudioList} />
                  {/* <div className="ganerator-song-title mb-5">
                  <div className="song-images">
                    <img src="assets/images/resources/ganerated-music.jpg" className="img-fluid" />
                  </div>
                  <div className="song-details">
                    <h3>Electronic Dance</h3>
                    <div className="selected-genr-list">
                      <span>Electronic Dance</span> <span>Piano</span> <span>Drums</span> <span>BPM : 20</span>{" "}
                      <span>Tempo : 115</span> <span>Durations : 60s</span>
                    </div>
                  </div>
                </div>
                <div className="ganerated-music-body">
                  <div className="ganerated-music-list">
                    <div className="ms-player-content">
                      <div className="ms-album">
                        <div className="ms-thumb">
                          <img src="assets/images/resources/music-list.jpg" alt="" />
                        </div>
                        <div className="ms-th-info">
                          <h4>Heroes of Tomorrow</h4>
                          <span>AI Generated</span>
                        </div>
                      </div>
                      <div className="ms-player jp-state-playing" id="jp_container_2">
                        <div className="jp-controls text-center">
                          <button className="jp-play" tabIndex="0">
                            <i className="fas fa-play"></i>
                          </button>
                        </div>
                        <div className="title-bar">
                          <div className="ms-top">
                            <h4 className="jp-title"></h4>
                            <div className="jp-time-holder">
                              <div className="jp-current-time" role="timer" aria-label="time">
                                &nbsp;
                              </div>
                              <div className="jp-duration" role="timer" aria-label="duration">
                                &nbsp;
                              </div>
                            </div>
                          </div>
                          <div className="jp-progress">
                            <div className="jp-seek-bar">
                              <div className="jp-play-bar"></div>
                            </div>
                          </div>
                        </div>
                        <div className="jp-volume-controls">
                          <button className="jp-mute" tabIndex="0">
                            <button
                              type="button"
                              className="btn-null mr-15"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              data-bs-title="Tooltip on top"
                            >
                              <i className="fas fa-link"></i>
                            </button>
                            <button
                              type="button"
                              className="btn-null mr-15"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              data-bs-title="Tooltip on top"
                            >
                              <i className="fas fa-heart"></i>
                            </button>
                            <button
                              type="button"
                              className="btn-null mr-15"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              data-bs-title="Tooltip on top"
                            >
                              <i className="fas fa-download"></i>
                            </button>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="ganerated-music-list">
                    <div className="ms-player-content">
                      <div className="ms-album">
                        <div className="ms-thumb">
                          <img src="assets/images/resources/music-list.jpg" alt="" />
                        </div>
                        <div className="ms-th-info">
                          <h4>Symphony of Victory</h4>
                          <span>AI Generated</span>
                        </div>
                      </div>
                      <div className="ms-player" id="jp_container_2">
                        <div className="jp-controls text-center">
                          <button className="jp-play" tabIndex="0">
                            <i className="fas fa-play"></i>
                          </button>
                        </div>
                        <div className="title-bar">
                          <div className="ms-top">
                            <h4 className="jp-title"></h4>
                            <div className="jp-time-holder">
                              <div className="jp-current-time" role="timer" aria-label="time">
                                &nbsp;
                              </div>
                              <div className="jp-duration" role="timer" aria-label="duration">
                                &nbsp;
                              </div>
                            </div>
                          </div>
                          <div className="jp-progress">
                            <div className="jp-seek-bar">
                              <div className="jp-play-bar"></div>
                            </div>
                          </div>
                        </div>
                        <div className="jp-volume-controls">
                          <button className="jp-mute" tabIndex="0">
                            <button
                              type="button"
                              className="btn-null mr-15"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              data-bs-title="Tooltip on top"
                            >
                              <i className="fas fa-link"></i>
                            </button>
                            <button
                              type="button"
                              className="btn-null mr-15"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              data-bs-title="Tooltip on top"
                            >
                              <i className="fas fa-heart"></i>
                            </button>
                            <button
                              type="button"
                              className="btn-null mr-15"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              data-bs-title="Tooltip on top"
                            >
                              <i className="fas fa-download"></i>
                            </button>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="ganerated-music-list">
                    <div className="ms-player-content">
                      <div className="ms-album">
                        <div className="ms-thumb">
                          <img src="assets/images/resources/music-list.jpg" alt="" />
                        </div>
                        <div className="ms-th-info">
                          <h4>Unbreakable Spirit</h4>
                          <span>AI Generated</span>
                        </div>
                      </div>
                      <div className="ms-player" id="jp_container_2">
                        <div className="jp-controls text-center">
                          <button className="jp-play" tabIndex="0">
                            <i className="fas fa-play"></i>
                          </button>
                        </div>
                        <div className="title-bar">
                          <div className="ms-top">
                            <h4 className="jp-title"></h4>
                            <div className="jp-time-holder">
                              <div className="jp-current-time" role="timer" aria-label="time">
                                &nbsp;
                              </div>
                              <div className="jp-duration" role="timer" aria-label="duration">
                                &nbsp;
                              </div>
                            </div>
                          </div>
                          <div className="jp-progress">
                            <div className="jp-seek-bar">
                              <div className="jp-play-bar"></div>
                            </div>
                          </div>
                        </div>
                        <div className="jp-volume-controls">
                          <button className="jp-mute" tabIndex="0">
                            <button
                              type="button"
                              className="btn-null mr-15"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              data-bs-title="Tooltip on top"
                            >
                              <i className="fas fa-link"></i>
                            </button>
                            <button
                              type="button"
                              className="btn-null mr-15"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              data-bs-title="Tooltip on top"
                            >
                              <i className="fas fa-heart"></i>
                            </button>
                            <button
                              type="button"
                              className="btn-null mr-15"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              data-bs-title="Tooltip on top"
                            >
                              <i className="fas fa-download"></i>
                            </button>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="ganerated-music-list">
                    <div className="ms-player-content">
                      <div className="ms-album">
                        <div className="ms-thumb">
                          <img src="assets/images/resources/music-list.jpg" alt="" />
                        </div>
                        <div className="ms-th-info">
                          <h4>March of Legends</h4>
                          <span>AI Generated</span>
                        </div>
                      </div>
                      <div className="ms-player" id="jp_container_2">
                        <div className="jp-controls text-center">
                          <button className="jp-play" tabIndex="0">
                            <i className="fas fa-play"></i>
                          </button>
                        </div>
                        <div className="title-bar">
                          <div className="ms-top">
                            <h4 className="jp-title"></h4>
                            <div className="jp-time-holder">
                              <div className="jp-current-time" role="timer" aria-label="time">
                                &nbsp;
                              </div>
                              <div className="jp-duration" role="timer" aria-label="duration">
                                &nbsp;
                              </div>
                            </div>
                          </div>
                          <div className="jp-progress">
                            <div className="jp-seek-bar">
                              <div className="jp-play-bar"></div>
                            </div>
                          </div>
                        </div>
                        <div className="jp-volume-controls">
                          <button className="jp-mute" tabIndex="0">
                            <button
                              type="button"
                              className="btn-null mr-15"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              data-bs-title="Tooltip on top"
                            >
                              <i className="fas fa-link"></i>
                            </button>
                            <button
                              type="button"
                              className="btn-null mr-15"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              data-bs-title="Tooltip on top"
                            >
                              <i className="fas fa-heart"></i>
                            </button>
                            <button
                              type="button"
                              className="btn-null mr-15"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              data-bs-title="Tooltip on top"
                            >
                              <i className="fas fa-download"></i>
                            </button>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="ganerated-music-list">
                    <div className="ms-player-content">
                      <div className="ms-album">
                        <div className="ms-thumb">
                          <img src="assets/images/resources/music-list.jpg" alt="" />
                        </div>
                        <div className="ms-th-info">
                          <h4>Ambient Cinematic</h4>
                          <span>AI Generated</span>
                        </div>
                      </div>
                      <div className="ms-player" id="jp_container_2">
                        <div className="jp-controls text-center">
                          <button className="jp-play" tabIndex="0">
                            <i className="fas fa-play"></i>
                          </button>
                        </div>
                        <div className="title-bar">
                          <div className="ms-top">
                            <h4 className="jp-title"></h4>
                            <div className="jp-time-holder">
                              <div className="jp-current-time" role="timer" aria-label="time">
                                &nbsp;
                              </div>
                              <div className="jp-duration" role="timer" aria-label="duration">
                                &nbsp;
                              </div>
                            </div>
                          </div>
                          <div className="jp-progress">
                            <div className="jp-seek-bar">
                              <div className="jp-play-bar"></div>
                            </div>
                          </div>
                        </div>
                        <div className="jp-volume-controls">
                          <button className="jp-mute" tabIndex="0">
                            <button
                              type="button"
                              className="btn-null mr-15"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              data-bs-title="Tooltip on top"
                            >
                              <i className="fas fa-link"></i>
                            </button>
                            <button
                              type="button"
                              className="btn-null mr-15"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              data-bs-title="Tooltip on top"
                            >
                              <i className="fas fa-heart"></i>
                            </button>
                            <button
                              type="button"
                              className="btn-null mr-15"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              data-bs-title="Tooltip on top"
                            >
                              <i className="fas fa-download"></i>
                            </button>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                </div>
              </div>
            </div>
          )}
        </div>
      </section>}
    </>
  );
};

export default MusicAssistant;
