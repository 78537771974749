import { Fragment, useContext } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./routes";
import Spinner from "./components/Spinner";
import { AppContext } from "./appContext";

function App() {
  const { isLoading, handleCloseMenu } = useContext(AppContext);

  return (
    <div onClick={handleCloseMenu}>
      {isLoading && <Spinner />}
      <Router>
        <Routes />
      </Router>
    </div>
  );
}

export default App;
